import React, { useContext } from 'react'
import { GeoContext } from '../state/geo'
import DefaultLayout from '../layouts/defaultLayout/defaultLayout'
import TextPage from '../components/textPage/textPage'
import BackButton from '../components/backButton/backButton'

const Terms: React.FC = () => {
  const { url } = useContext(GeoContext)
  return (
    <DefaultLayout hasNav={false} hasSearch={false} seo={{ title: 'Terms' }}>
      <TextPage>
        <BackButton />
        <h1 className="h1 h--decor text-page__title">Terms of use</h1>
        <h2 className="h3 h--decor text-page__section-header">1. Preamble</h2>

        <p>
          These terms of use (&quot;Terms of Use&quot;) for the online service
          UX Strategy Kit provided via the website [uxtoolkit.{url}.design]
          (“[UX Strategy Kit]”, “the Service(s)”) which is operated and
          maintained by Merck KGaA, Frankfurter Straße 250, 64293 Darmstadt,
          Deutschland, tel.: +49 6151 72-0, fax: +49 6151 72-2000, email
          address:{' '}
          <a href={`mailto:service@${url}group.com`}>service@{url}group.com</a>{' '}
          <strong>(“Company“; “we”; “us”; “our”)</strong> apply to the use of UX
          Strategy Kit between us and the User <strong>(“User”)</strong>. Please
          read these terms and conditions carefully. If you do not agree to
          these terms and conditions, please do not access this website or any
          pages thereof (hereinafter referred to as the <strong>“Site”</strong>
          ).
        </p>

        <h2 className="h3 h--decor text-page__section-header">
          Use of Content
        </h2>

        <p>
          The content of this Site is available for informational purposes only.
          The posting of content and access to this Site does not constitute,
          either explicitly or implicitly, any provision of services or products
          by us or any of our affiliates.
        </p>

        <h2 className="h3 h--decor text-page__section-header">Copyright</h2>

        <p>
          The entire content of this Site is the property of the Company, its
          direct or indirect subsidiaries (hereinafter referred to collectively
          as “Group“) or third parties and is protected by applicable copyright
          law with all rights reserved. All rights in the pages, site content
          and arrangement are owned by the Group and its licensors. You must not
          sell, distribute, publish, broadcast, circulate or commercially
          exploit Site information in any manner without our express written
          consent. Any downloading or otherwise copying from this Site will not
          transfer title to any software or material to you. You may not
          reproduce (in whole or part), transmit (by electronic means or
          otherwise), modify, display, redeliver, license, link or otherwise use
          this Site for any public or commercial purpose without our prior
          permission. Nothing at this Site shall be construed as granting a
          license or right to use any image, trademark, service mark or logo,
          all of which are the property of Group. Group reserves all rights with
          respect to its proprietary information or material at this Site and
          will enforce such rights to the full extent of applicable copyright
          and trademark law.
        </p>

        <h2 className="h3 h--decor text-page__section-header">No Warranty</h2>

        <p>
          The information and opinions contained on this Site, including
          hyperlinks or references to other sites, are provided ‘as is’ without
          any warranty of any kind, either expressed or implied, to the fullest
          extent permissible pursuant to applicable law. We do not warrant the
          adequacy, accuracy, reliability or completeness of any information on
          the Site and expressly disclaims any liability for errors or omissions
          therein. Company further assumes no responsibility for, and makes no
          warranties that, functions contained on this Site will be
          uninterrupted or error-free, that defects will be corrected, or that
          this Site or the servers that make it available will be free of
          viruses or other harmful components.
        </p>

        <h2 className="h3 h--decor text-page__section-header">
          Limitation of Liability
        </h2>

        <p>Our liability is limited to intent and gross negligence.</p>

        <h2 className="h3 h--decor text-page__section-header">Linked Sites</h2>

        <p>
          Certain hyperlinks or websites referenced on the Site may for your
          convenience forward you to third parties’ websites, which generally
          are recognised by their top level domain name. Their content has not
          been investigated or analysed by us, and we do not warrant the
          adequacy, accuracy, reliability or completeness of any information on
          hyperlinked or referenced websites and expressly disclaim any
          liability for any and all of their content. You are responsible for
          evaluating the adequacy, accuracy or reliability and completeness of
          any information or other content available on hyperlinked or
          referenced websites.
          <br />A hyperlink to another website is meant for convenience only and
          does not imply that Company endorses or approves of the website or any
          products or services that it describes. Company reserves the right to
          terminate any hyperlink or hyperlinking program at any time.
        </p>

        <h2 className="h3 h--decor text-page__section-header">
          Local Legal Restrictions
        </h2>
        <p>
          The Site is not directed to any person in any jurisdiction where (by
          reason of that person’s nationality, residence or otherwise) the
          publication or availability of the Site is prohibited. Persons in
          respect of whom such prohibitions apply must not access the Site.
        </p>

        <h2 className="h3 h--decor text-page__section-header">
          Reservation of Rights
        </h2>
        <p>
          We reserve the right to change, modify, add to, or remove portions of
          these terms of use at any time.
        </p>

        <h2 className="h3 h--decor text-page__section-header">Severability</h2>
        <p>
          In the event that any provision of these terms is held unenforceable,
          the validity or enforceability of the remaining provisions will not be
          affected.
        </p>

        <h2 className="h3 h--decor text-page__section-header">
          Dispute Resolution
        </h2>

        <p>
          Company is not willing or obliged to participate in dispute resolution
          proceedings before a dispute resolution body for consumer disputes.
        </p>
      </TextPage>
    </DefaultLayout>
  )
}

export default Terms
