import React from 'react'
import BackToTop from '../backToTop/backToTop'
import './textPage.sass'

const TextPage = ({ children }) => (
  <div className="text-page">
    <div className="text-page__wrapper">
      {children}
      <BackToTop />
    </div>
  </div>
)

export default TextPage
